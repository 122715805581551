<template>
  <div
    class="pt-5 card-footer bottom-0 sm:absolute sm:bottom-4 sm:pt-10 sm:mt-3"
  >
    <div class="font-sans text-gray-400 text-sm sm:text-center">
      <span>
        Design & code by
      </span>
      <a href="https://www.twitter.com/anggxyz/" target="_new">
        @angelagilhotra
      </a>
      <span>
        , special thanks to
      </span>
      <a href="https://www.instagram.com/malayvasa/" target="_new">
        @malayvasa
      </a>
      <br />
      <a
        href="https://opensea.io/collection/kernel-fellowship-gratitude"
        target="_new"
        class="text-xs"
      >
        View collection on OpenSea
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer"
};
</script>
